import React from "react"
import SEO from "../common/seo/seo"

class Test extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SEO title="Apply" />
        <div
          id="SOIDIV_HookPointAdvisorCall"
          data-so-page="HookPointAdvisorCall"
          data-height="550"
          data-style="border: 1px solid #D8D8D8; min-width: 290px; max-width: 900px;"
          data-psz="00"
        />
      </React.Fragment>
    )
  }
}

export default Test
